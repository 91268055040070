<script setup lang="ts">
import type { IRate } from "~/interfaces";
import BaseForm from "~/components/ui/BaseForm.vue";
import Rata from "~/components/inputs/Rata.vue";
import { getPromoModel, getModelPromoKO } from "~/utils/promo";
const locale = await getLocale("forms", "rate_promo")

defineComponent({
  name: "RatePromo",
});

const timeOut = ref();

onMounted(() => {
  updateStateValue("showFlashMessage", true)
  timeOut.value = setTimeout(() => {
    updateStateValue("flashMessage", 'Affrettati! Un utente ha ricevuto esito positivo alla sua richiesta di prestito proprio ora.')
  }, 5000)
  emit("init")
})
onUnmounted(() => {
  clearTimeout(timeOut.value);
})

const emit = defineEmits(["init", "submit"]);
const model = ref<IRate>(getPromoModel()!);
const selected = ref<IRate>(model.value);

const promoTerms = computed(() => {
  const promoKO = getModelPromoKO()
  if (!promoKO) return ''
  return locale.promo_terms.replace('%taeg_ko', promoKO.taeg).replace('%secci_ko', promoKO.secci_url)
})

const setRateValue = (model: IRate) => {
  updateStateValue("rate", model.rate);
  emit("submit", model);
};
</script>

<template>
  <BaseForm>
    <template #upper>
      <div class="offers my-4 sm:my-8">
        <div>
          <div class="text-center lg:text-left py-1 px-2 sm:px-16">
            <h1 class="text-xl sm:text-3xl font-semibold">
              {{ locale.title }}
            </h1>
          </div>
          <div class="bg-yellow text-center lg:text-left py-1 mt-4">
            <h3 class="text-base mx-auto px-4 py-1 sm:px-16">
              <span>{{ locale.offer_helper }}</span>
            </h3>
          </div>
        </div>
        <div class="px-2 sm:px-16 mx-auto lg:container pt-6">
          <ul class="mb-4 lg:mb-6" data-testid="rataOur">
            <Rata
              :model="model"
              :active="
                selected.rate === model.rate &&
                selected.imp_credito === model.imp_credito
              "
              type="our"
              @submit="setRateValue"
            />
          </ul>
          <p ref="terms"
            class="text-center sm:text-left text-11px sm:text-11.5px leading-tight mt-10 lg:mt-6 px-2"
            v-html="promoTerms"
          ></p>
        </div>
      </div>
      <div class="spacer h-6"></div>
    </template>
  </BaseForm>
</template>

<style scoped></style>
